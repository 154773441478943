/**
 * Generates validation rules for text fields with customizable error messages.
 * @param {string} fieldLabel - Optional. The label of the field to customize the error messages.
 * @param {number} maxLength - Optional. Maximum allowed length of the input.
 * @param {boolean} isRequired - Optional. field is required true or false.
 * @returns {Array} Array of validation functions.
 */

// Fixed maximum lengths that can never be exceeded
const MAX_TEXT_LENGTH = 100; // The absolute maximum length for text fields
const MAX_ADDRESS_LENGTH = 200; // The absolute maximum length for address fields

const ValidationService = {
  store: null,
  init(opts) {
    if (opts.store) {
      this.store = opts.store;
    }
  },
  generateTextValidationRules(fieldLabel = "Input", inputMaxLength = MAX_TEXT_LENGTH, isRequired = true) {
    const effectiveMaxLength = inputMaxLength ? inputMaxLength : MAX_TEXT_LENGTH;
    let rules = [
      v => {
        if (v && v.trim().length > effectiveMaxLength) {
          return `${fieldLabel} must be less than ${effectiveMaxLength} characters.`;
        } else {
          return true;
        }
      }
    ];
    if (isRequired) {
      rules.push(v => (!!v && !!v.trim()) || `${fieldLabel} is required.`);
    }
    return rules;
  },
  generateAddressValidationRules(fieldLabel = "Address", inputMaxLength = MAX_ADDRESS_LENGTH) {
    const effectiveMaxLength = inputMaxLength <= MAX_ADDRESS_LENGTH ? inputMaxLength : MAX_ADDRESS_LENGTH;
    return [
      v => !!v.trim() || `${fieldLabel} is required.`,
      v => v.trim().length <= effectiveMaxLength || `${fieldLabel} must be less than ${effectiveMaxLength} characters.`
    ];
  },
  generateEmailValidationRules() {
    return [v => !!v.trim() || `E-mail is required.`];
  },
  generateDropdownValidationRules(fieldLabel = "Selection") {
    return [v => (v && v !== "") || `${fieldLabel} is required.`];
  },
  generateNotWhitespacesRule(fieldLabel = "Field") {
    return v => {
      if (v === "" || v === null) return true;
      return v.trim().length > 0 || `${fieldLabel} cannot consist of only whitespaces.`;
    };
  }
};

export default ValidationService;
